<template>
  <div>
    <img :src="partyImg" alt="" srcset="" style="width: 100%" />
    <div class="wrap-party" v-loading="loading">
      <el-empty description="园区党建暂无数据" v-if="list.length === 0"></el-empty>
      <list-item v-else v-for="(item, index) in list" :key="item.href + index" :info="item" />
    </div>
    <div style="margin: 48px 0 24px 0;">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo" :page-size="pageSize"
          layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
  </div>
  
</template>

<script>
import pic_party from "../assets/pics/partyImg.png";
import pic_defaultBG from "../assets/pics/bodyBG.png";
import pic_partyBG from "../assets/pics/partyBG.png";
import listItem from "../components/party/partyItem.vue";
import { api_listByType } from "../api/homeapis";
import { getData } from "../utils/homePublicMethods";

export default {
  components: {
    listItem,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 10,
      loading: false,
      list: [
        {
          title: "",
          time: "",
          url: "",
        },
      ],
      partyImg: pic_party,
    };
  },
  methods: {
    // filterUrl(element) {
    //   let url = "";
    //   if (element.externalUrl == null) {
    //     url = `/news?id=${element.id}&&theme=party`;
    //   } else {
    //     url = element.externalUrl;
    //   }
    //   return url;
    // },
    // getData1() {
    //   $http
    //     .get(api_listByType, {
    //       params: {
    //         infoType: "3",
    //         webDisplay: "1",
    //         pageNo:this.pageNo
    //       },
    //     })
    //     .then((res) => {
    //       let datas = res.data.result.records;
    //       this.total = res.data.result.total
    //       this.list.splice(0, this.list.length)
    //       for (let index = 0; index < datas.length; index++) {
    //         const element = datas[index];
    //         let { title, publishDate } = element;
    //         this.list.push({
    //           title,
    //           time: publishDate,
    //           url: this.filterUrl(element),
    //         });
    //       }
    //     });
    // },
    changeBackgroundImg(img) {
      let app_element = document.getElementById("app");
      if (img === 'party') {
        app_element.style.backgroundImage = `url(${pic_partyBG})`
        app_element.style.backgroundSize = '110% 300px';
      }
      else if (img === 'default') {
        app_element.style.backgroundImage = `url(${pic_defaultBG})`
        app_element.style.backgroundSize = '100% 100%';
      }
    },
    handleCurrentChange(val) {
      this.loading = true
      getData('3', this.list, 'party', val, this.setTotal)
        .catch(err => { this.loading = false })
        .finally(() => {
          this.loading = false
        })
    },
    setTotal(total) {
      this.total = total
    }
  },
  mounted() {
    this.loading = true
    getData('3', this.list, 'party', this.pageNo, this.setTotal).
      catch(err => { this.loading = false })
      .finally(() => {
        this.loading = false
      })
    this.changeBackgroundImg('party');
  },
  destroyed() {
    this.changeBackgroundImg('default');
  },
};
</script>

<style  scoped>
.wrap-party {
  background: rgba(245, 245, 245, 0.3);
  padding: 5px;
  margin-bottom: 12px;
  border-radius: 12px;
  box-shadow: 1px 1px 5px rgba(1, 1, 1, .3);
}
</style>
