<template>
  <div class="nav">
    <div v-for="(item, index) in links" :key="item.name + index">
      <el-dropdown class="router-btn">
        <!--没有子选项-->
        <router-link
          :to="item.to"
          class="link"
          :style="setColor(index)"
          v-if="item.childLinks.length == 0"
          >{{ item.name }}</router-link
        >
        <!--有子选项-->
        <div v-else>
          <span class="link">{{ item.name }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(childitem, index) in item.childLinks"
              :key="childitem.name + index"
            >
              <router-link :to="childitem.to" style="text-decoration: none">{{
                childitem.name
              }}</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      links: [
        {
          to: "/",
          name: "首页",
          childLinks: [],
        },
        {
          to: "/realtimeinfo",
          name: "动态信息",
          childLinks: [],
        },
        {
          to: "/notice",
          name: "通知公告",
          childLinks: [],
        },
        {
          to: "/majorprj",
          name: "重大项目",
          childLinks: [],
        },
        {
          to: "/investment",
          name: "三招三引",
          childLinks: [
            // { to: "/investment?type=finance", name: "招商引资" },
            // { to: "/investment?type=talent", name: "招才引智" },
            // { to: "/investment?type=institutes", name: "招院引所" },
          ],
        },
        {
          to: "/policies",
          name: "政策法规",
          childLinks: [],
        },
        {
          to: "/partbuilding",
          name: "园区党建",
          childLinks: [],
        },
        {
          to: "/entrepreneur",
          name: "企业家风采",
          childLinks: [],
        },
        {
          to: "/expert",
          name: "专家论坛",
          childLinks: [],
        },
        // {
        //   to: "/recruit",
        //   name: "招聘信息",
        //   childLinks: [],
        // },
      ],
    };
  },
  methods: {
    setColor(index) {
      if (index == this.activeIndex) {
        return 'color:#135fc2;font-weight: bold;border-bottom: 0.2rem white solid;  text-shadow: 1px 0 0 #ffffff, -1px 0 0 #ffffff, 0 -1px 0 #ffffff,0 1px 0 #ffffff;'
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        // console.log(newRoute);
        let { path } = newRoute;
        for (let index = 0; index < this.links.length; index++) {
          const element = this.links[index];
          if (element.to == path) {
            this.activeIndex = index;
            // alert(path + "   " + index)
            return;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  height: 3.125rem;
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  box-sizing: border-box;
  border-radius: 6px;
}

.router-btn {
  height: 100%;
  border: 0;
  padding-top: 14px;
  box-sizing: border-box;
  font-size: 16px;
}

.router-btn .link {
  display: inline-block;
  text-decoration: none;
  color: white;
  height: 100%;
}

.router-btn .link:hover {
  cursor: pointer;
  border-bottom: 0.2rem white solid;
  color: #135fc2 !important;
  font-weight: bold;
  text-shadow: 1px 0 0 #ffffff, -1px 0 0 #ffffff, 0 -1px 0 #ffffff,
    0 1px 0 #ffffff;
}
</style>
