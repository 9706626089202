<template>
  <div class="search-wrap">
    <el-empty description="暂无数据" v-show="list.length === 0"></el-empty>
    <list-item v-for="item in list" :key="item.title" :info="item" />
  </div>
</template>


<script>
import { api_search } from "../api/homeapis";
import listItem from "../components/public/listitem.vue";

export default {
  components: {
    listItem,
  },
  data() {
    return {
      list: [],
      //{
      //   title: "",
      //   source: "",
      //   time: "",
      //   content: "",
      //   href: "",
      //   coverimg:"",
      // },
    };
  },
  methods: {
    filterUrl(element) {
      let url = "";
      if (element.externalUrl == null) {
        url = `/news?id=${element.id}`;
      } else {
        url = element.externalUrl;
      }
      return url;
    },
    getData(keyword) {
      $http
        .get(api_search, {
          params: {
            keyWord: keyword,
            webDisplay: "1",
          },
        })
        .then((res) => {
          let _list = res.data.result;
          this.list = [];
          if (_list.length === 0) {
            this.$message({
              message: `暂无与“${keyword}”相关的内容`,
              duration: 1500,
            });
            return;
          }
          for (let index = 0; index < _list.length; index++) {
            const element = _list[index];
            let { title, dept, publishDate, content, coverImage } = element;
            this.list.push({
              title,
              source: dept,
              time: publishDate,
              content,
              coverImage,
              url: this.filterUrl(element),
            });
          }
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newpath) {
        let keyword = newpath.query.keyword;
        this.getData(keyword);
      },
    },
  },
};
</script>

<style scoped>
</style>
