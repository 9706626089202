<template>
  <div class="wrap1">
    <NewsFramework
      style="width: 320px; margin-right: 8px"
      :tabConfig="tabConfig1"
      :moreHrefs="hrefConfig1"
    />
    <NewsFramework
      style="min-width: 320px; width:320px; margin-right: 8px"
      :tabConfig="tabConfig2"
      :moreHrefs="hrefConfig2"
    />
    <NewsFramework
      style="width: 320px; margin-right: 8px"
      :tabConfig="tabConfig3"
      :moreHrefs="hrefConfig3"
    />
    <NewsFramework
      style="width: 320px; margin-right: 8px"
      :tabConfig="tabConfig4"
      :moreHrefs="hrefConfig4"
    />
  </div>
</template>

<script>
import NewsFramework from "@/components/public/NewsFramework.vue";
import pic_tzgg from "@/assets/pics/tzggLogo.png";
import { getData, getMajorPrjData } from "../../utils/homePublicMethods";

export default {
  components: {
    NewsFramework,
  },
  data() {
    return {
      tabConfig1: [
        {
          pic: pic_tzgg,
          name: "重大项目",
          style: "bold",
          contentlist: [
            // {
            //   title: "24小时自助法院启用 成都高新区不断提升营商环境数字化水平",
            //   time: "2022-04-14",
            //   source: "四川新闻网",
            //   url: "/news",
            // },
          ]
        },
      ],
      tabConfig2: [
        {
          pic: pic_tzgg,
          name: "三招三引",
          style: "bold",
          contentlist: [
            // {
            //   title: "招聘：大客户总监-电力行业",
            //   time: "2022-04-20",
            //   source: "成都市xxx运营管理有限公司",
            //   url: "/recruitmentdetails?id=0",
            // },
          ],
        },
      ],
      tabConfig3: [
        {
          pic: pic_tzgg,
          name: "政策法规",
          style: "small",
          contentlist: [],
        },
      ],
      tabConfig4: [
        {
          pic: pic_tzgg,
          name: "园区党建",
          style: "small",
          contentlist: [],
        },
      ],
      hrefConfig1: ["/majorprj"],
      hrefConfig2: ["/investment"],
      hrefConfig3: ["/policies"],
      hrefConfig4: ["/partbuilding"],
    };
  },
  methods: {
  },
  mounted() {
    getData('5',this.tabConfig1[0].contentlist);
    getData('4',this.tabConfig2[0].contentlist);
    getData("2", this.tabConfig3[0].contentlist);
    getData("3", this.tabConfig4[0].contentlist);
  },
};
</script>

<style scoped>
.wrap1 {
  display: flex;
}
</style>