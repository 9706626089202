<template>
  <div class="wrap-news-framework">
    <head class="head">
      <div>
        <div
          class="tab"
          v-for="(item, index) in tabConfig"
          :key="item.name + index"
          :style="showcolor(index)"
          @mouseenter="() => enterTab(index)"
        >
          <img
            :src="item.pic"
            alt=""
            srcset=""
            style="height: 12px; width: 12px; margin: 0"
          />
          {{ item.name }}
        </div>
      </div>
      <a
        @click="
          () => {
            pathPush(moreHrefs[showIndex]);
          }
        "
        style="color: #959393"
        >MORE >></a
      >
    </head>
    <div class="content">
      <el-empty
        description="暂无数据"
        v-show="tabConfig[showIndex].contentlist.length === 0"
        style="height: 20px; width: 90%"
      ></el-empty>
      <div
        v-for="(item, index) in tabConfig[showIndex].contentlist"
        :key="item.title + index"
      >
        <!--第1种样式: small-->
        <div
          v-if="tabConfig[showIndex].style === 'small'"
          class="content-item-small"
        >
          <a
            @click="
              () => {
                pathPush(item.url);
              }
            "
            class="ellipsis-text-overflow"
            >{{ item.title }}</a
          >
          <p>{{ item.time.substr(0, 10) }}</p>
        </div>
        <!--第2种样式: bold-->
        <div
          v-else-if="tabConfig[showIndex].style === 'bold'"
          class="content-item-bold"
        >
          <div
            class="ellipsis-text-overflow title"
            @click="
              () => {
                pathPush(item.url);
              }
            "
          >
            {{ item.title }}
          </div>
          <div>
            <div
              style="
                justify-content: space-between;
                width: 100%;
                display: flex;
                margin: 0;
              "
            >
              <p class="vice-title">{{ item.time.substr(0, 10) }}</p>
              <p class="vice-title">
                {{ item.source.substr(0, 15)
                }}{{ item.source.length > 15 ? ".." : "" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabConfig: Array,
    moreHrefs: Array,
  },
  data() {
    return {
      // tab展示第几个
      showIndex: 0,
      hrefs: [],
    };
  },
  methods: {
    enterTab(index) {
      this.showIndex = index;
    },
    /**
     * 网页跳转。传过来的可能是个完整地址或者路由地址，需要判断一下
     */
    pathPush(url) {
      if (url.includes("http")) {
        // window.location.href = url;  // 不打开新网页
        window.open(url); // 打开新网页展示
      } else {
        this.$router.push({ path: url }); // 不打开新网页
        //let newUrl = this.$router.resolve({ path: url });
        //window.open(newUrl.href);  // 打开新网页展示
      }
    },
    showcolor(index) {
      if (index === this.showIndex) {
        return "color:#137aff;";
      } else {
        return "color:black;";
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.wrap-news-framework {
  border-radius: 0.625rem;
  padding: 6px;
  box-sizing: border-box;
  background: white;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.head {
  display: flex;
  height: 1.5rem;
  font-size: 14px;
  justify-content: space-between;
}

.tab {
  cursor: pointer;
  margin-right: 12px;
  display: inline-block;
  font-weight: bold;
}

.tab:hover {
  border-bottom: 2px #137aff solid;
}

.head span:hover {
  cursor: pointer;
}

.content {
  width: 100%;
  height: calc(100% - 1.5rem);
  text-align: left;
  margin-top: 3px;
  overflow: hidden;
}

.content-item-small {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  padding: 3.5px 0 2px 0;
  font-size: 14px;
  justify-content: space-between;
  display: flex;
  height: 24px;
}

.content-item-small::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #1079ff;
  margin: 8px 3px;
}

.content-item-small img {
  height: 14px;
  width: 14px;
  margin: 0;
}

.content-item-small a {
  text-decoration: none;
  width: calc(100% - 1.5rem - 72px);
}

.content-item-small a:hover {
  color: #137aff;
}

.content-item-small p {
  margin: 0;
  width: 72px;
  overflow: hidden;
  color: #b1b1b1;
}

.content-item-bold {
  overflow: hidden;
  font-size: 14px;
  margin-bottom: 9px;
  cursor: pointer;
}

.content-item-bold .title {
  margin: 0;
  display: inline-block;
  text-decoration: none;
  width: 90%;
  font-weight: bold;
  margin-bottom: 5px;
}

.content-item-bold .title:hover {
  color: #137aff;
}

.content-item-bold .vice-title {
  margin: 0;
  color: #c2c2c2;
}
</style>
