<template>
  <div class="wrap">
    <h1 class="title">{{ data.title }}</h1>
    <div class="vice-title">
      <span>来源：{{ data.source }}</span>
      <span style="margin-left: 44px">发布时间：{{ data.time}}</span>
    </div>
     <img 
     v-if="data.contentImages[0] || data.coverImage"
     :src="data.contentImages[0] || data.coverImage" 
     srcset="" 
     style="width:50%;margin: 12px 0;">
    <div class="content" id="test1">
      <!-- {{ data.content }} -->
    </div>
  </div>
</template>

<script>
import { api_newsDetail } from '../api/homeapis'
import pic_defaultBG from "../assets/pics/bodyBG.png";
import pic_partyBG from "../assets/pics/partyBG.png";

export default {
  data() {
    return {
      data: {
        title: "",
        source: "",
        time: "",
        content: "",
        contentImages:[],
        coverImage:""
      },
    };
  },
  methods: {
    async getData(id) {
      let _data;
      await $http
        .get(api_newsDetail, {
          params: {
            id,
          },
        })
        .then((res) => {
          let data = res.data.result;
          const {title, dept, publishDate, content, contentImages, coverImage} = data
          // console.log(data)
          this.data = _data = {
            title,
            source:dept,
            time:publishDate,
            content,
            contentImages:contentImages?contentImages.split(','):"",
            coverImage
          }
        });
      return _data
    },
    /**
     * 党建模块需要更换页面的背景
     * @param {*} img 
     */
    changeBackgroundImg(img) {
      let app_element = document.getElementById("app");
      if(img ==='party'){
        app_element.style.backgroundImage = `url(${pic_partyBG})`
        app_element.style.backgroundSize =  '110% 300px';
      }
      else if (img === 'default'){
        app_element.style.backgroundImage = `url(${pic_defaultBG})`
        app_element.style.backgroundSize =  '100% 100%';
      }
    },
  },
  mounted() {
    let params = this.$route.query;
    let { id, theme } = params;
    if(theme === 'party'){
      this.changeBackgroundImg('party')
    }
    let _data = this.getData(id);
    _data.then(res=>{
      //console.log(res)
      let contentElement = document.getElementById('test1');
      let newContent = res.content.replace(new RegExp('<p>&nbsp;</p>','g'), "");
      newContent = newContent.replace(new RegExp('<p','g'), "<p style='margin: 12px 0 -22px 0;'");
      contentElement.innerHTML = newContent;   
    }) 
  },
  destroyed(){
    this.changeBackgroundImg('default')
  }
};
</script>

<style scoped>
.wrap {
  background: white;
  padding: 32px 16px;
  box-shadow: 1px 1px 5px rgba(1, 1, 1, .3);
  margin-bottom: 12px;
}

.title {
  font-size: 20px;
  color: black;
  font-weight: bolder;
}

.vice-title {
  color: #969495;
  font-size: 14px;
  margin-top: 24px;
}

.content {
  white-space: pre-wrap;
  text-align: left;
  font-size: 16pt; 
  color: #333333;
  line-height: 22pt;
}
</style>