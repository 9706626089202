<template>
  <div>
    <first-region class="line-1" />
    <second-region class="line-2" />
    <third-region class="line-3" /> 
    <fourth-region class="line-4" />
    <fifth-region class="line-5" />
  </div>
</template>

<script>
import FirstRegion from "@/components/home/FirstRegion.vue";
import SecondRegion from "@/components/home/SecondRegion.vue";
import ThirdRegion from "@/components/home/ThirdRegion.vue";
import FourthRegion from "@/components/home/FourthRegion.vue";
import FifthRegion from "@/components/home/FifthRegion.vue";

export default {
  name: "Home",
  components: {
    FirstRegion,
    SecondRegion,
    ThirdRegion,
    FourthRegion,
    FifthRegion,
  }
};
</script>

<style scoped>
.line-1 {
  height: 250px;
  margin-top: 0.375rem;
}

.line-2 {
  height: 12rem;
  margin-top: 12px;
  overflow: hidden;
}

.line-3 {
  height: 120px;
  margin-top: 6px;
}

.line-4 {
  height: 60px;
  margin-top: 8px;
}

.line-5 {
  height: 6.875rem;
  background-color: #2c3e50;
  margin-top: 8px;
}
</style>
