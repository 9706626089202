<template>
  <el-carousel class="mcarousel">
    <el-carousel-item v-for="item in config" :key="item.url">
      <a :href="item.url" target="_blank">
        <img :src="item.pic" alt="" srcset=""/>
      </a>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import {api_carousel} from '../../api/homeapis'

export default {
  data() {
    return {
      config: [
        // {
        //   pic: pic_1,
        //   url: "http://lm.scppc.org.cn/show-85-670-1.html"
        // }
      ],
    };
  },
  methods:{
    getData(){
      $http.get(api_carousel,{isDisplay:'1'}).then(res=>{
        let list = res.data.result.records;
        this.config = [];
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          this.config.push({
            pic:element.picSrc.replace("https://", "http://"),
            url:element.pageUrl || "",
          })
        }
      })
    }
  },
  mounted(){
    this.getData();
  }

};
</script>

<style>
.mcarousel {
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.mcarousel img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
