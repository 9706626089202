<template>
  <div>
    <div id="app">
      <PublicHead />
      <RouterLink />
      <router-view style="min-height: 50rem;margin-top: 6px;border-radius: 10px;" />
    </div>
    <PublicBottom />
  </div>
</template>

<script type="text/javascript">
import PublicHead from "@/components/public/PublicHead.vue";
import RouterLink from "@/components/public/PublicRouterLink.vue";
import PublicBottom from "@/components/public/PublicBottom.vue";
export default {
  components: {
    PublicHead,
    RouterLink,
    PublicBottom,
  },
  methods:{
    
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  padding: 0 calc(50% - 1310px / 2);
  /* 在views/partyBuilding里面会对背景图片进行控制，如果要改背景图还需去那里同步 */
  background: url(./assets/pics/bodyBG.png);  
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* 省略号代替超出部分，供给全局调用 */
.ellipsis-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* a标签点击过后不变样式 */
a:link,
a:visited,
a:hover,
a:active {
  cursor: pointer;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
