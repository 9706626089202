<template>
  <div class="wrap">
    <img :src="pic_map" alt="" srcset="" />
    <a href="https://www.scgxqpt.com/web/index.html" target="_blank">
      进入平台 >></a
    >
  </div>
</template>

<script>
import pic_map from '@/assets/pics/linkBG.png';
export default {
    data(){
        return{
            pic_map
        }
    }
};
</script>

<style scoped>
.wrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.wrap img {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}

.wrap a {
  position: absolute;
  bottom: 12px;
  left: 45%;
  background: rgba(255, 255, 255, 0.7);
  border: 1px #137aff solid;
  color: #137aff;
  cursor: pointer;
  font-size: 15px;
  padding: 6px;
  text-decoration: none;
}
</style>