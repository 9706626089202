<template>
  <div class="wrap">
    <!-- <el-popover placement="top-start" trigger="hover">
      <img :src="pic_2" alt="" srcset="" style="width: 150px; height: 150px" />
      <span slot="reference">四川省高新技术产业研究院 四川省高新区协会</span>
    </el-popover> -->
    四川省高新技术产业研究院
    &nbsp;&nbsp;
    四川省高新区协会
    &nbsp;&nbsp;
    <el-popover placement="top-start" trigger="hover">
      <img :src="pic_1" alt="" srcset="" style="width: 150px; height: 150px" />
      <span slot="reference">四川省科技厅</span>
    </el-popover>
	&nbsp;&nbsp;
    <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:white; text-decoration: none;" target="_blank">蜀ICP备13001376号-25</a> 
  </div>
</template>

<script>
import pic_1 from "../../assets/pics/btmpic1.png";
import pic_2 from "../../assets/pics/btmpic2.png";

export default {
  data() {
    return {
      pic_1,
      pic_2,
    };
  },
};
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 4.375rem;
  line-height: 4.375rem;
  color: #ffffff;
  font-size: 0.8125rem;
  text-align: center;
  background-color: rgb(29, 29, 29);
}
</style>
