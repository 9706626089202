<template>
  <div class="wrap">
    <div
      v-for="(item, index) in config"
      :key="item.content + index"
      style="cursor:pointer;"
      @click="() => goto(item.url)"
    >
      <img :src="item.pic" alt="" srcset="" />
      <span :style="item.style">{{ item.content }}</span>
    </div>
  </div>
</template>

<script>
import pic_1 from "@/assets/pics/lbx1.png";
import pic_2 from "@/assets/pics/lbx2.png";
import pic_3 from "@/assets/pics/lbx3.png";
import pic_4 from "@/assets/pics/lbx4.png";
import pic_5 from "@/assets/pics/lbx5.png";
import pic_6 from "@/assets/pics/lbx6.png";
import pic_7 from "@/assets/pics/lbx7.png";

export default {
  data() {
    return {
      config: [
        {
          url: "/entrepreneur",
          content: "企业家风采",
          pic: pic_1,
          style: {
            color: "#59cca9",
          },
        },
        {
          url: "/majorprj",
          content: "重大项目",
          pic: pic_2,
          style: {
            color: "#58b6e5",
          },
        },
        {
          url: "/investment?type=finance",
          content: "三招三引",
          pic: pic_3,
          style: {
            color: "#fa7673",
          },
        },
        {
          url: "/policies",
          content: "政策法规",
          pic: pic_4,
          style: {
            color: "#a66bfa",
          },
        },
        {
          url: "/expert",
          content: "专家论坛",
          pic: pic_5,
          style: {
            color: "#4080fe",
          },
        },
        {
          url: "",
          content: "科技创新",
          pic: pic_6,
          style: {
            color: "#e071f3",
          },
        },
        {
          url: "https://report.scstrs.cn/",
          content: "科技报告共享",
          pic: pic_7,
          style: {
            color: "#f4a73e",
          },
        },
      ],
    };
  },
  methods: {
    goto(url) {
      if (url.includes("http")) {
        // window.location.href = url;  // 不打开新网页
        window.open(url); // 打开新网页展示
      } else {
        this.$router.push({ path: url }); // 不打开新网页
        //let newUrl = this.$router.resolve({ path: url });
        //window.open(newUrl.href);  // 打开新网页展示
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  height: 100%;
  display: flex;
}

.wrap div {
  width: calc(100% / 8);
  margin-right: calc(100% / 8 / 7);
  position: relative;
  height: 100%;
}

.wrap div img {
  position: absolute;
  height: 100%;
  left: 0;
}

.wrap div span {
  position: absolute;
  left: 40%;
  top: 30%;
  font-size: 16px;
  font-weight: bold;
}
</style>