<template>
  <div class="wrap-item" @click="pathPush(info.url)">
    <h3 style="margin: 5px">{{ info.title }}</h3>
    <div class="content">
      <el-image
        style="width: 230px; height: 150px"
        :src="info.coverImage"
        :fit="cover"
        v-if="info.coverImage"
      ></el-image>
      <div class="contenttext" :style="info.coverImage ? '' : 'width:100%'">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ showContent }}</span>
      </div>
    </div>
    <footer>
      <span id="list-item">{{ info.source }}</span>
      <span>{{ info.time }}</span>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    // info:{
    //   title:'',
    //   source:'',
    //   time:'',
    //   content:'',
    //   url:'',
    //   coverImage:''
    // }
  },
  data() {
    return {
      showContent: "",
    };
  },
  methods: {
    filterHTML2Text(str) {
      if (str == "") return;
      return str
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "")
        .replace(/<[^>]+?>/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, " ")
        .replace(/&ldquo;/g, " ")
        .replace(/&rdquo;/g, " ")
        .replace(/&nbsp;/gi, "")
        .replace(/&hellip;/gi, "")
        .replace(/&lsquo;/gi, "")
        .replace(/&rsquo;/gi, "")
        .replace(/&ensp;/gi, "")
        .replace(/&mdash;/gi, "")
        .replace(/>/g, " ");
    },
    pathPush(url) {
      if (url.includes("http")) {
        // window.location.href = url;  // 不打开新网页
        window.open(url); // 打开新网页展示
      } else {
        this.$router.push({ path: url }); // 不打开新网页
        //let newUrl = this.$router.resolve({ path: url });
        //window.open(newUrl.href);  // 打开新网页展示
      }
    },
  },
  mounted() {
    this.showContent = this.filterHTML2Text(this.info.content);
  },
};
</script>

<style  scoped>
.wrap-item {
  text-align: left;
  margin: 8px 0px;
  padding: 18px 12px;
  box-sizing: border-box;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.wrap-item:hover {
  opacity: 0.8;
}

.wrap-item .content {
  display: flex;
  padding: 6px;
  box-sizing: border-box;
  justify-content: space-between;
}

.wrap-item .contenttext {
  color: #969495;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-top: 9px;
  line-height: 24px;
  max-height: 92px;
  width: calc(100% - 250px);
}

.wrap-item footer {
  display: flex;
  margin-top: 16px;
  color: #b1b1b1;
  justify-content: space-between;
}
</style>