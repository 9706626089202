<template>
  <div class="wrap">
    <h2>招聘详情</h2>
    <div class="content">
      <p><span>需求标题</span>：{{ title||'暂无' }}</p>
      <p><span>行业领域</span>：{{ profession||'暂无' }}</p>
      <p><span>需求公司</span>：{{ company||'暂无' }}</p>
      <p><span>地址</span>：{{ address||'暂无' }}</p>
      <p><span>工作年限</span>：{{ workage||'暂无' }}</p>
      <p><span>学历要求</span>：{{ degree||'暂无' }}</p>
      <p><span>需求内容</span>：</p>
      <div>
        {{ content||'暂无' }}
      </div>
    </div>
  </div>
</template>


<script>
import { api_needDetail } from "../api/homeapis";

export default {
  data() {
    return {
      titie: "",
      profession: "",
      company: "",
      workage: "",
      degree: "",
      content: ``,
      address:"",

    };
  },
  methods: {
    getDetail(id) {
      // console.log(id);
      $http
        .get(api_needDetail, {
          params: {
            id,
          },
        })
        .then((res) => {
          let {
            title,
            industryType,
            needCompany,
            workYear,
            education,
            description,
            address
          } = res.data.result;
          // console.log(res)
          this.title = title;
          this.profession = industryType;
          this.company = needCompany;
          this.workage = workYear;
          this.degree = education;
          this.content = description;
          this.address = address;
        });
    },
  },
  mounted() {
    let params = this.$route.query;
    let { id } = params;
    this.getDetail(id);
  },
};
</script>

<style scoped>
.wrap {
  padding: 10px 24px;
}

.content {
  white-space: pre-wrap;
  text-align: left;
  font-size: 16pt;
  color: #333333;
  font-family: "仿宋_GB2312";
  line-height: 28pt;
}

.content span {
  font-weight: bold;
}
</style>