<template>
  <div class="wrap">
    <div
      @click="clickLogo"
      class="title"
    >
      <img :src="pic_logo" style="height: 110px;margin-top: -15px;" />
      <div class="titlecontent">
        <p
          style="
            font-size: 32px;
            letter-spacing: 5px;
            font-weight: bold;
            margin-top: 18px;
          "
        >
          四川高新区
        </p>
        <p style="font-size: 16px">Sichuan High-tech Zone</p>
      </div>
    </div>

    <div style="display: flex">
      <!-- <img :src="pic_wechatapp" alt="微信小程序" srcset="" style="width: 96px;height: 102px;margin-right: 12px;"/> -->
      <img :src="pic_appdown" alt="app下载" srcset="" style="width: 96px;height: 102px; "/>
      <div class="search">
        <el-input
          placeholder="请输入想要搜索的内容"
          v-model="searchInput"
          clearable
          id="head_input"
        />
        <el-button
          style="margin: 0 10px 0 5px; font-size: 16px; color: #0b69d3"
          @click="clickSearchBtn"
          >搜索</el-button
        >
      </div>
      <!-- <div class="login">
				<button @click="clickRegisterBtn">注册</button>
				<button style="background-color: white;color:#0b69d3;" @click="clickLoginBtn">登录</button>
			</div> -->
    </div>
  </div>
</template>

<script>
import pic_logo from "@/assets/pics/sclogo.png";
import pic_appdown from "@/assets/pics/AppDownload.png";
import pic_wechatapp from "@/assets/pics/wechatapp.png";


export default {
  name: "Head",
  data() {
    return {
      pic_logo,
      pic_wechatapp,
      pic_appdown,
      searchInput: "",
    };
  },
  methods: {
    clickLogo(){
      //alert('')
      this.$router.push({path:'/'})
    },
    clickSearchBtn() {
      if (this.searchInput.trim() === "") {
        this.$message({
          message:'请输入有效内容',
          duration:1500
        });
		return;
      }
      this.$router.push({ path: `/searchInfo?keyword=${this.searchInput}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap {
  height: 90px;
  text-align: left;
  padding: 1.25rem 0;
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.titlecontent {
  color: white;
  height: 100%;
}

.titlecontent p {
  margin: 6px 0;
}

.search {
  display: flex;
  height: 39px;
  box-sizing: border-box;
  margin: 16px 0 0 12px;
}

.login {
  margin-top: 22px;
  border-left: 1px solid white;
  height: 1.875rem;
}

.login button {
  width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 1px white solid;
  color: white;
  background: transparent;
  margin-left: 10px;
}

button:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
