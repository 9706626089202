<template>
  <div class="wrap-party" v-loading="loading">
    <el-empty description="暂无企业家数据" v-show="list.length === 0"></el-empty> 
    <list-item v-for="(item,index) in list" :key="item.item + index" :info="item"/>
    <div style="margin: 48px 0 24px 0;">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo" :page-size="pageSize"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import listItem from "../components/public/listitem.vue";
import { getData } from "../utils/homePublicMethods";

export default {
  components: {
    listItem,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 10,
      loading: false,
      list: [
        {
          title: "",
          source: "",
          time: "",
          content: "",
          url: "",
        },
      ],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.loading = true
      getData('7', this.list, null, val, this.setTotal)
        .catch(err => { this.loading = false })
        .finally(() => {
          this.loading = false
        })
    },
    setTotal(total) {
      this.total = total
    }
  },
  mounted() {
    getData('7', this.list, null, this.pageNo, this.setTotal)
      .catch(err => { this.loading = false })
      .finally(() => {
        this.loading = false
      })
  },
};
</script>

<style  scoped>
.wrap-party {
  background: rgba(245, 245, 245, 0.3);
  padding: 0 0 24px 0;
}
</style>
