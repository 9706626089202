<template>
  <div class="wrap">
    <div class="left">
        <a v-for="item in config.left" :key="item.content" :href="item.url" target="_blank">{{item.content}}</a>
    </div>
    <div class="right">
        <a v-for="item in config.right" :key="item.content" :href="item.url" target="_blank">{{item.content}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        left: [
          { content: "成都高新区", url: "http://www.cdht.gov.cn/cdht/shouye/sy.shtml" },
          { content: "绵阳高新区", url: "http://gaoxinqu.my.gov.cn/index.html" },
          { content: "内江高新区", url: "https://gxq.neijiang.gov.cn/gxq/index.shtml" },
          { content: "攀枝花钒钛高新区", url: "http://ft.panzhihua.gov.cn/" },
          { content: "自贡高新区", url: "http://www.zggx.gov.cn/" },
          { content: "乐山高新区", url: "https://gxq.leshan.gov.cn/" },
          { content: "泸州高新区", url: "http://gxq.luzhou.gov.cn/wzsy" },
          { content: "德阳高新区", url: "http://gxq.deyang.gov.cn/" },
        ],
        right: [
          { content: "宜宾高新区", url: "http://www.ybgxjscyyq.com/" },
          { content: "广安高新区", url: "" },
          { content: "游仙高新区", url: "http://www.yxgxb.com/" },
          { content: "资阳高新区", url: "http://www.zygxq.gov.cn/" },
          { content: "绵竹高新区", url: "" },
          { content: "江油高新区", url: "https://sc.zhaoshang.net/yuanqu/detail/12923" },
          { content: "南充高新区", url: "" },
          { content: "德阳旌阳高新区", url: "" },
          { content: "达州高新区", url: "" },
          { content: "温江高新区", url: "" },
          { content: "成都金牛高新区", url: "" },
          { content: "遂宁船山高新区", url: "" },
          { content: "沿滩高新区", url: "" },
          { content: "遂宁高新区", url: "https://gxq.suining.gov.cn/" },
          { content: "眉山高新区", url: "https://sc.zhaoshang.net/yuanqu/detail/12664" },
          { content: "中江高新区", url: "" },
          { content: "新都高新区", url: "" },
          { content: "攀枝花高新区", url: "" },
          { content: "安州高新区", url: "" },
          {content:'凉山高新区', url:'http://xcft.lsz.gov.cn/'}
        ],
      },
    };
  },
};
</script>

<style scoped>
.wrap {
  background-image: url("../../assets/pics/qggxq.png");
  height: 100%;
  background-size: contain;
  display: flex;
}

a{
    text-decoration: none;
    font-size: 13px;
    color: white;
    display: inline-block;
}

.left {
  width: 420px;
  height: 70px;
  text-align: left;
  margin: 24px 10px;
}

.left a{
    width: 110px;
    margin-top: 6px;
    margin-left: 24px;
}

.right {
  height: 70px;
  width: 860px;
  margin: 24px -10px;
  text-align: left;
}

.right a{
    width: calc(100% /8);
    margin-top: 5px;
}
</style>