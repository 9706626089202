import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NewsDetail from "../views/NewsDetail.vue";
import RecruitmentDetail from "../views/RecruitmentDetail.vue";
import Notice from '../views/Notice.vue';
import MajorProj from '../views/MajorProj.vue';
import MajorProjDetail from '../views/MajorProjDetail.vue';
import Investment from '../views/Investment';
import Policies from '../views/Policies';
import PartBuilding from '../views/Partybuilding';
import Entrepre from '../views/Entrepreneur';
import Expert from '../views/Expert';
import Recruit from '../views/Recruit'
import SearchRes from '../views/SearchResult'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/realtimeinfo",
    name: "realtimeinfo",
    component: function () {
      return import("../views/DynamicInfo.vue");
    },
  },
  {
    path: "/news",
    name: "news",
    component: NewsDetail,
  },
  {
    path: "/recruitmentdetails",
    name: "recruitmentdetails",
    component: RecruitmentDetail,
  },
  {
    path: "/notice",
    name: "notice",
    component: Notice,
  },
  {
    path: "/majorprj",
    name: "majorprj",
    component: MajorProj,
  },
  {
    path: "/majorprjdetail",
    name: "majorprjdetail",
    component: MajorProjDetail,
  },
  {
    path: "/investment",
    name: "investment",
    component: Investment,
  },
  {
    path: "/policies",
    name: "policies",
    component: Policies,
  },
  {
    path: "/partbuilding",
    name: "partbuilding",
    component: PartBuilding,
  },
  {
    path: "/entrepreneur",
    name: "entrepreneur",
    component: Entrepre,
  },
  {
    path: "/expert",
    name: "expert",
    component: Expert,
  },
  {
    path: "/recruit",
    name: "recruit",
    component: Recruit,
  },
  {
    path:'/searchInfo',
    name:'searchInfo',
    component:SearchRes
  }
];

const router = new VueRouter({
  routes,
  mode:"history"
});

export default router;
