<template>
  <div class="wrap-dynainfo">
    <el-empty description="招聘信息暂无" v-if="list.length === 0"></el-empty>
    <list-item v-else v-for="(item,index) in list" :key="item.item + index" :info="item"/>
  </div>
</template>

<script>
import listItem from "../components/public/listitem.vue";
import { api_needListByType } from "../api/homeapis";

export default {
  components: {
    listItem,
  },
  data() {
    return {
      list: [
        // {
        //   title: "",
        //   source: "",
        //   time: "",
        //   content: "",
        //   href: "",
        // },
      ],
    };
  },
  methods: {
    filterUrl(element){
      let url = `/recruitmentdetails?id=${element.id}`;
      // if(element.content){
      //   url = `/recruitmentdetails?id=${element.id}`;
      // }
      // else{
      //   url = element.externalUrl;
      // }
      return url;
    },
    getData() {
      $http
        .get(api_needListByType, {
          params: {
            // infoType: "1",
          },
        })
        .then((res) => {
          let datas = res.data.result.records; 
          this.list = [];
          for (let index = 0; index < datas.length; index++) {
            const element = datas[index];
            let { title, publishDate, content, needCompany } = element;
            this.list.push({
              title,
              source:needCompany,
              time:publishDate,
              content:"",
              href:this.filterUrl(element)
            })
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style  scoped>
.wrap-dynainfo {
  background: rgba(245, 245, 245, 0.3);
  padding: 0 0 24px 0;
}
</style>
