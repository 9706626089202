<template>
  <div class="party-item" @click="goto(info.url)">
    <span class="title">{{ info.title }}</span>
    <span class="time">{{ info.time }}</span>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    //  {
    //   title: "",
    //   time: "",
    //   href: "",
    // },
  },
  methods: {
    goto(url) {
      if (url.includes("http")) {
        // window.location.href = url;  // 不打开新网页
        window.open(url); // 打开新网页展示
      } else {
        this.$router.push({ path: url }); // 不打开新网页
        //let newUrl = this.$router.resolve({ path: url });
        //window.open(newUrl.href);  // 打开新网页展示
      }
    },
  },
};
</script>

<style scoped>
.party-item {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  font-size: 14px;
  height: 30px;
}

.party-item:hover {
  cursor: pointer;
  opacity: 0.7;
}

.title {
  font-weight: bolder;
  color: black;
}

.title::before {
  content: "●";
  color: red;
  margin-right: 12px;
}

.time {
  color: #b2b2b2;
}
</style>