<template>
  <div class="wrap-fg">
    <Carousel style="width: 28.125rem" />
    <NewsFramework
      style="width: 400px; margin-left: 8px"
      :tabConfig="tabConfig"
      :moreHrefs="hrefConfig"
    />
    <FourRectangle style="width: 450px; margin-left: 4px" />
  </div>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import NewsFramework from "@/components/public/NewsFramework.vue";
import FourRectangle from "@/components/home/FourRectangle.vue";
import pic_tzgg from "@/assets/pics/tzggLogo.png";
import pic_dtxx from "@/assets/pics/dtxxLogo.png";
import { getData } from "../../utils/homePublicMethods";

export default {
  components: {
    Carousel,
    NewsFramework,
    FourRectangle,
  },
  data() {
    return {
      tabConfig: [
        {
          pic: pic_tzgg,
          name: "通知公告",
          style: "small",
          contentlist: [
            // {
            //   title: "模板1：外部链接跳转",
            //   time: "2022-04-14",
            //   url: "https://cbgc.scol.com.cn/news/3471616?from=androidapp&app_id=cbgc",
            // },
            // {
            //   title: "模板2：内部新闻页",
            //   time: "2022-05-04",
            //   url: "/news?id=12345678",
            // },
          ],
        },
        {
          pic: pic_dtxx,
          name: "动态信息",
          style: "small",
          contentlist: [],
        },
      ],
      hrefConfig:['/notice','/realtimeinfo']
    };
  },
  mounted() {
    getData("0",this.tabConfig[0].contentlist); // 通知公告
    getData("1",this.tabConfig[1].contentlist); // 动态信息
  },
};
</script>

<style scoped>
.wrap-fg {
  display: flex;
  height: 100%;
}
</style>