import {api_listByType, api_needListByType} from '../api/homeapis';

function filterURL(obj,themetype) {
  // console.log(themetype)
  let { externalUrl, id } = obj;
  let url = "";
  if (externalUrl == null) {
    url = `/news?id=${id}`;
     if(themetype != undefined){
       url = url + `&theme=${themetype}`
     }
  } else {
    url = externalUrl;
  }
  return url;
}
/**
 * 获取咨询列表信息
 */
export async function getData(infoType, viewModel, themetype, pageNo = 1, setTotal) {
  await window.$http
    .get(api_listByType, {
      params: {
        webDisplay: "1",
        infoType,
        pageNo,
      }
    })
    .then((res) => {
      let data = res.data.result.records;
      setTotal ? setTotal(res.data.result.total) : "";
      // console.log(infoType, res)
      viewModel.splice(0, viewModel.length)
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        // console.log(element)
        let title = element.title;
        let time = element.publishDate;
        let source = element.dept;
        let content = element.content;
        let coverImage = element.coverImage;
        let url = filterURL(element,themetype);
        viewModel.push({
          title,
          time,
          url,
          source,
          content,
          coverImage,
        });
      }
    }).catch((error)=>{
      console.log(error)
    })
}

/**
 * 过滤招聘信息的URL
 * @param {*} element 
 * @returns 
 */
function filterRecruitUrl(element){
  let {id} = element;
  return `recruitmentdetails?id=${id}`
}


/**
 * 需求大厅
 */
export async function getNeedData(needType,viewModel) {
  await $http.get(api_needListByType, {
    needType,
    pageNo:'1',
    pageSize:"10"
  }).then((res) => {
    let data = res.data.result.records;
    // console.log(res);
    viewModel.contentlist = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let {title, createTime, needCompany} = element;
      let url = filterRecruitUrl(element);
      viewModel.contentlist.push({
        title,
        time:createTime,
        source:needCompany,
        url
      })
    }
  });
}

/**
 * 重大项目
 */

 export function getMajorPrjData(viewModel) {
  $http
    .get(api_listByType, {
      params: {
        infoType: "4",
      },
    })
    .then((res) => {
      let datas = res.data.result.records; 
      viewModel.contentlist = [];
      for (let index = 0; index < datas.length; index++) {
        const element = datas[index];
        let { projectName, createTime, projectIntroduce, company,id} = element;
        viewModel.contentlist.push({
          title:projectName,
          source:company,
          time:createTime,
          content:projectIntroduce,
          url:`/majorprjdetail?id=${id}`
        })
      }
    });
}

