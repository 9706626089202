<template>
  <table class="wrap">
    <tr>
      <td>
        <a @click="pathPush(url1)"><img :src="pic_1" alt="" srcset="" /></a>
      </td>
      <td>
        <a @click="pathPush(url2)"><img :src="pic_2" alt="" srcset="" /></a>
      </td>
    </tr>
    <tr>
      <td>
        <a @click="pathPush(url3)"><img :src="pic_3" alt="" srcset="" /></a>
      </td>
      <td>
        <a @click="pathPush(url4)"><img :src="pic_4" alt="" srcset="" /></a>
      </td>
    </tr>
  </table>
</template>

<script>
import pic_1 from "@/assets/pics/h26BBtn1.png";
import pic_2 from "@/assets/pics/h26BBtn2.png";
import pic_3 from "@/assets/pics/h26BBtn3.png";
import pic_4 from "@/assets/pics/h26BBtn4.png";
export default {
  data() {
    return {
      pic_1,
      pic_2,
      pic_3,
      pic_4,
      url1: "http://202.61.89.168/gxyq/",
      url2: "http://202.61.89.120/",
      url3: "/",
      url4: "https://tyrz.chinatorch.org.cn/hjismp/a/login#tj",
    };
  },
  methods: {
    pathPush(url) {
      if (!url.includes("http")){
        this.$router.push({ path: url });
      }
      else
        window.open(url, "_blank");     
    },
  },
};
</script>

<style scoped>
.wrap {
}

.wrap img {
  height: 120px;
}
</style>
