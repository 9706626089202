<template>
  <div class="wrap-prj">
    <h2>项目详情</h2>
    <div class="vicetitle-prj">
      <p><span>创建时间</span>：{{ data.publishDate || "暂无" }}</p>
      <p><span>更新时间</span>：{{ data.publishDate || "暂无" }}</p>
    </div>
    <div class="content-prj">
      <p><span>项目名称</span>：{{ data.title || "暂无" }}</p>
      <p><span>需求公司</span>：{{ data.dept || "暂无" }}</p>
      <p><span>审核阶段</span>：{{ data.approvalStatus_dictText || "暂无" }}</p>
      <p><span>项目介绍</span>：</p>
      <div>
        {{ data.projectIntroduce || "暂无" }}
      </div>
    </div>
  </div>
</template>


<script>
import { api_newsDetail } from "../api/homeapis";

export default {
  data() {
    return {
      data: {
        projectName: "暂无",
        projectBatch: "暂无",
        totalInvestment: "暂无",
        company: "暂无",
        publishDate: "暂无",
        updateTime: "暂无",
        projectIntroduce: "暂无",
        projectYear: "暂无",
        address: "暂无",
      },
    };
  },
  methods: {
    getDetail(id) {
      // console.log(id);
      $http
        .get(api_newsDetail, {
          params: {
            id,
          },
        })
        .then((res) => {
          this.data = res.data.result;
        });
    },
  },
  mounted() {
    let params = this.$route.query;
    let { id } = params;
    this.getDetail(id);
  },
};
</script>

<style scoped>
.wrap-prj {
  padding: 10px 24px;
  background: white;
}

.content-prj {
  white-space: pre-wrap;
  text-align: left;
  font-size: 16pt;
  color: #333333;
  font-family: "仿宋_GB2312";
  line-height: 28pt;
}

.content-prj span {
  font-weight: bold;
}

.vicetitle-prj {
  display: flex;
  color: #969495;
  font-size: 14px;
  margin-top: 12px;
  justify-content: space-between;
  width: 50%;
  margin:0 auto;
}
</style>